// window.addEventListener('load', () => {
export function loadPostCode() {
  // select2
  function initStreetData() {
    return [
      {
        id: 0,
        text: '町域以後'
      }
    ]
  }
  
  $('.p-street-address').select2({
    theme: 'bootstrap4',
    dropdownCssClass: 'text-md',
    data: initStreetData()
  })

  // 郵便番号→住所について タグ(class指定＝h-adr) で囲む
  const adrs = document.querySelectorAll('.h-adr')
  adrs.forEach((adr) => {
    // 郵便番号入力欄のclass指定
    const zip = adr.querySelector('.p-postal-code')
    // 住所欄(都道府県)のclass指定
    const state = adr.querySelector('.p-state')
    // 住所欄(都道府県＋市区町村)のclass指定
    const stateTown = adr.querySelector('.p-state-town')
    // 住所欄(市区町村)のclass指定
    const town = adr.querySelector('.p-town')
    // 住所欄(町域)のclass指定
    const street = adr.querySelector('.p-street')
    // 住所欄(市区町村+町域)のclass指定
    const townStreet = adr.querySelector('.p-town-street')
    // 住所欄(都道府県+市区町村+町域)のclass指定
    const stateTownStreet = adr.querySelector('.p-state-town-street')

    // 都道府県Selectboxのclass指定
    const stateSelect = adr.querySelector('.p-region')
    // 市区町村Selectboxのclass指定
    const townSelect = adr.querySelector('.p-locality')
    // 町域Selectboxのclass指定
    const streetSelect = adr.querySelector('.p-street-address')

    // 住所詳細
    const address = adr.querySelector('.p-extended-address')

    // APIキーを指定して住所補完サービスを作成
    let apiObserve = false
    const api = new postcodejp.address.AutoComplementService(postCodeJpApiKey);

    // 郵便番号テキストボックスを指定
    api.setZipTextbox(zip)

    // 住所補完フィールドを追加
    if (state != null) {
      api.add(new postcodejp.address.StateTextbox(state));
      apiObserve = true
    }
    if (stateTown != null) {
      api.add(new postcodejp.address.StateTownTextbox(stateTown));
      apiObserve = true
    }
    if (town != null) {
      api.add(new postcodejp.address.TownTextbox(town));
      apiObserve = true
    }
    if (street != null) {
      api.add(new postcodejp.address.StreetTextbox(street));
      apiObserve = true
    }
    if (townStreet != null) {
      api.add(new postcodejp.address.TownStreetTextbox(townStreet));
      apiObserve = true
    }
    if (stateTownStreet != null) {
      api.add(new postcodejp.address.StateTownStreetTextbox(stateTownStreet));
      apiObserve = true
    }
    if (stateSelect != null) {
      // 都道府県選択　→ 市区町村リスト更新
      stateSelect.addEventListener('change', (event) => {
        getCities(event.target.value, townSelect)
      })
      if (stateSelect.value != '') {
        stateSelect.dispatchEvent(new Event('change'))
      }
    }
    if (townSelect != null && streetSelect != null) {
      // 市区町村選択　→ 町域リスト更新
      townSelect.addEventListener('change', (event) => {
        let streetData
        const $select = $(streetSelect).parent().find("select")
        $(streetSelect).parent().find("span").remove()

        $select.select2({
          theme: 'bootstrap4',
          dropdownCssClass: 'text-md',
          ajax: {
            url: postcodeURL + 'postcodes?',
            headers: postcodeHeaders,
            dataType: 'json',
            delay: 500,
            data: (params) => {
              return {
                limit: 50,
                fields: "town", // postcodeは常に対象
                filter: "prefCode==" + stateSelect.value.padStart(2, '0') + " and city==" + event.target.value + ((params.term) ? (" and town==*" + params.term + "*") : ""),
                cursor: params.nextCursor || ""
              }
            },
            processResults: (data, params) => {
              if (params.nextCursor != null) {
                streetData = []
              } else {
                streetData = initStreetData()
              }
              data.data.forEach((town) => {
                streetData.push({
                  id: town.postcode,
                  text: town.town
                })
              })
              params.nextCursor = data.nextCursor
              return {
                results: streetData,
                pagination: {
                  more: data.hasNext
                }
              }
            }
          }
        })
        // 町域選択 → 郵便番号
        $select.on("select2:select", function(e) {
          if (e.params.data.selected) {
            zip.value = e.params.data.id
          }
        })
      })
      if (townSelect.value != '') {
        townSelect.dispatchEvent(new Event('change'))
      }

      const form = adr.closest('form')
      form.addEventListener('submit', () => {
        const idx = streetSelect.selectedIndex
        const selected = streetSelect.options[idx]
        if (idx == 0) {
          selected.setAttribute('value', '')
        } else {
          selected.setAttribute('value', selected.text)
        }
      })
    }

    // 郵便番号テキストボックスの監視を開始
    if (apiObserve) {
      api.observe();
    }

    // 郵便番号 → 都道府県、市区町村、町域(SelectBox)選択
    if (zip != null && stateSelect != null && townSelect != null && streetSelect != null && address != null) {
      zip.addEventListener('input', (event) => {
        if (event.target.value.length >= 7) {
          findByPostcode(event.target.value, stateSelect, townSelect, streetSelect, address)
        }
      })
      if (zip.value != '') {
        findByPostcode(zip.value, stateSelect, townSelect, streetSelect, address)
      }
    }
  })
}

const postcodeURL = 'https://apis.postcode-jp.com/api/v4/'
const postcodeHeaders = {'apikey': postCodeJpApiKey}
const axiosBase = require('axios')
const postCodeJpAxios = axiosBase.create({
  baseURL: postcodeURL,
  headers: postcodeHeaders
})

function getCities(prefCode, select) {
  const selected = select.value
  postCodeJpAxios.get('prefectures/' + prefCode.padStart(2, '0') + '/cities')
    .then(res => {
      select.innerHTML = ''
      let option = document.createElement('option')
      option.setAttribute('value', '')
      option.innerHTML = '市区町村'
      select.appendChild(option)
      res.data.data.forEach((city) => {
        let option = document.createElement('option')
        option.setAttribute('value', city.city)
        if (selected == city.city) {
          option.setAttribute('selected', true)
        }
        option.innerHTML = city.city
        select.appendChild(option)
      })
    })
    .catch(function(error) {
      console.log(error)
    });
}

function findByPostcode(postcode, state, town, street, address) {
  postCodeJpAxios.get('postcodes/' + postcode,{
      params: {
        fields: "prefCode,city,town",
        normalize: true
      }
    })
    .then(res => {
      const prefCode = Number(res.data[0].prefCode)
      let option = state.querySelector('option[value="' + prefCode + '"]')
      option.setAttribute('selected', true)

      town.innerHTML = ''
      option = document.createElement('option')
      option.innerHTML = '市区町村'
      town.appendChild(option)
      option = document.createElement('option')
      option.innerHTML = res.data[0].city
      option.value = res.data[0].city
      option.setAttribute('selected', true)
      town.appendChild(option)

      street.innerHTML = ''
      option = document.createElement('option')
      option.innerHTML = '町域以後'
      street.appendChild(option)

      for (var i=0; i<res.data.length; i++) {
        option = document.createElement('option')
        option.innerHTML = res.data[i].town
        option.value = res.data[i].postcode
        if (i == 0) {
          option.setAttribute('selected', true)
        }
        street.appendChild(option)
      }

      // if (address.value != '') {
      //   address.value = address.value.replace(res.data[0].town, '')
      // }
    })
    .catch(function(error) {
      console.log(error)
    });
}