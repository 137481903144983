// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// ActiveStorage.start()

import "core-js/stable"
import "regenerator-runtime/runtime"

import 'summernote/dist/summernote-bs4'
import 'summernote/dist/summernote-bs4.css'
import 'summernote/lang/summernote-ja-JP'
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

require('bootstrap/dist/js/bootstrap')
require('admin-lte/dist/js/adminlte')
const moment = require('moment/moment.js')
require('daterangepicker/daterangepicker.js')
require('select2/dist/js/select2.full')
require('../select2.customSelectionAdapter.min.js')

require('admin-lte/plugins/chart.js/Chart')

const postcodejp = require('postcodejp')

const bsCustomFileInput = require('bs-custom-file-input/dist/bs-custom-file-input')

require("@fortawesome/fontawesome-free/js/all")

require("../stylesheets/application")
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
const axios = require('axios')

window.$ = jQuery;

window.addEventListener('load', (event) => {
  moment.locale('ja')
  loadDatePicker()
  
  // timePicker あると autoReply 無効になる
  $(".datetimepicker").daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    timePicker: true,
    timePicker24Hour: true,
    drops: 'auto',
    timePickerIncrement: 10,
    // autoApply: true,
    locale: {
      daysOfWeek: [
        "日", "月", "火", "水", "木", "金", "土"
      ],
      monthNames: [
        "1月", "2月", "3月",
        "4月", "5月", "6月",
        "7月", "8月", "9月",
        "10月", "11月", "12月"
      ],
      format: 'YYYY/MM/DD HH:mm',
      applyLabel: '選択',
      cancelLabel: 'キャンセル'
    }
  }).on('apply.daterangepicker', (ev, picker) => {
    if (picker.element[0].tagName == "DIV") {
      picker.element[0].querySelector('input[type=text]').value = picker.startDate.format("YYYY/MM/DD HH:mm")
    } else {
      picker.element.val(picker.startDate.format("YYYY/MM/DD HH:mm"))
    }
  })

  //年月
  $(".monthpicker").daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    drops: 'auto',
    autoApply: true, // これが効かない
    locale: {
      daysOfWeek: [
        "日", "月", "火", "水", "木", "金", "土"
      ],
      monthNames: [
        "1月", "2月", "3月",
        "4月", "5月", "6月",
        "7月", "8月", "9月",
        "10月", "11月", "12月"
      ],
      format: 'YYYY-MM',
      applyLabel: '選択',
      cancelLabel: 'キャンセル'
    }
  }).on('apply.daterangepicker', (ev, picker) => {
    if (picker.element[0].tagName == "DIV") {
      picker.element[0].querySelector('input[type=text]').value = picker.startDate.format("YYYY-MM")
    } else {
      picker.element.val(picker.startDate.format("YYYY-MM"))
    }
  })

  // select2
  const CustomSelectionAdapter = $.fn.select2.amd.require('select2/selection/customSelectionAdapter')
  $('.select2').select2({
    tags: true,
    placeholder: "メンバーを選択してください",
    selectionAdapter: CustomSelectionAdapter
  })
  $('.select2-single').select2({
    theme: 'bootstrap4'
  })

  postcodejp.loadPostCode()
  loadSummernote()
  bsCustomFileInput.init()

  const previewButtons = document.querySelectorAll('.btn-show-preview')
  previewButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      const form = event.target.closest('form')
      const title = event.target.dataset.title
      const summernoteEditor = form.querySelector('.summernote-editor')
      document.querySelector('#preview-modal .modal-title').innerHTML = title
      document.querySelector('#preview-modal .preview-content').innerHTML = summernoteEditor.value
    })
  })

  loadSortableList

  const maxrows = document.querySelectorAll('select[name="per"]')
  maxrows.forEach((e) => {
    e.addEventListener('change', (ev) => {
      e.form.submit()
    })
  })

  const btnSyncNpPaymentStates = document.querySelectorAll('.btn-sync-np-payment-state')
  btnSyncNpPaymentStates.forEach((button) => {
    button.addEventListener('ajax:before', (event) => {
      const icon = event.target.querySelector('i,span,svg')
      icon.classList.add('fa-spin')
    })
    button.addEventListener('ajax:success', (event) => {
      const paymentState = event.detail[0]['payment_state']
      console.debug('Np PS: ', paymentState)
      const npPaymentStates = document.querySelectorAll('.received-order-np-payment-state')
      npPaymentStates.forEach((npPaymentState) => {
        npPaymentState.innerHTML = paymentState['name']
      })
      const icon = event.target.querySelector('i,span,svg')
      icon.classList.remove('fa-spin')
    })
  })

})

$(document).on("partial:loaded", function(event) {
  console.debug('partial:loaded event: ', event)
  loadDatePicker()
  loadSummernote()
})

const loadDatePicker = () => {
  $(".datepicker").daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    drops: 'auto',
    autoApply: true, // これが効かない
    locale: {
      daysOfWeek: [
        "日", "月", "火", "水", "木", "金", "土"
      ],
      monthNames: [
        "1月", "2月", "3月",
        "4月", "5月", "6月",
        "7月", "8月", "9月",
        "10月", "11月", "12月"
      ],
      format: 'YYYY/MM/DD',
      applyLabel: '選択',
      cancelLabel: 'キャンセル'
    }
  }).on('apply.daterangepicker', (ev, picker) => {
    picker.element.val(picker.startDate.format("YYYY/MM/DD"))
  })
}

const loadSummernote = () => {
  document.querySelectorAll('.summernote-editor').forEach((textArea) => {
    const height = textArea.dataset.height || 640
    $(textArea).summernote({
      height: height,
      lang: 'ja-JP',
      styleTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough']],
        ['fontstyle', ['fontsize', 'forecolor']],
        ['paragraph', ['paragraph']],
        ['insert', ['link', 'picture', 'table']],
        ['misc', ['codeview']]
      ],
      callbacks: {
        onBlurCodeview: function() {
          $(this).val($(this).summernote('code'));
        },
        onPaste: function (e) {
          var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
          e.preventDefault();
          // $(this).summernote('editor.insertText', bufferText);
          bufferText = bufferText.replace(/\r?\n/g, '<br>');
          $(this).summernote('pasteHTML', `${bufferText}`);
        }
      }
    })
  })
}

const loadSortableList = () => {
  const els = document.getElementsByClassName('sortable');

  for (let i = 0; i < els.length; i++) {
    new Sortable(els[i], {
      filter: '.filtered', // 'filtered' class is not draggable
      animation: 150,
      onMove: function(/**Event*/evt) {
        if (evt.related.classList.contains('filtered')) {
          return false;
        }
      },
      onEnd: function (/**Event*/evt) {
        let children = evt.to.children;
        for (let i = 0; i < children.length; i++) {
          let order = children[i].querySelector("input[name$='[row_order]']");
          if (order) {
            order.value = i;
          }
        }
      },
    });
  }
}

$(function(){
  const replaceHyphen = (input) => {
    return input.replace(/[^0-9]/g, '');
  }

  const hankaku2Zenkaku = (str) => {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }


  const trimSpace = (str) => {
    return str.replace(/[\s|　]+|[\s|　]+/g,'')
  }


  $(".search_form_tel_remove_hyphen").on('blur',function(e){
    var input = $(this).val();
    input = trimSpace(input);
    input = hankaku2Zenkaku(input);
    input = replaceHyphen(input);
    $(this).val(input);
  });

  $(".search_form_remove_space").on('blur', (event) => {
    var input = $(this).val()
    input = trimSpace(input)
    $(this).val(input)
  })
})
